import React, { useEffect, useState } from "react";
import screen_one from "../../../src/images/site-img/Screen 28-left.png";
import screen_two from "../../../src/images/site-img/Screen 55-left.png";
import tournaments_img from "../../../src/images/site-img/game-icon-yellow.svg";
import card_img from "../../images/site-img/card_black_background.jpg";
import instant_img from "../../images/site-img/instant-withdrawal.webp";
import gpay from "../../images/site-img/g-pay.png";
import phone_pay from "../../images/site-img/phone_pay.png";
import paytm from "../../images/site-img/paytm.png";
import imps from "../../images/site-img/imps.png";
import AddCardIcon from "@mui/icons-material/AddCard";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import gameplay from "../../images/site-img/smooth-gameplay-usp-v2.webp";
import moves_img from "../../images/site-img/check-game-usp-v2.webp";
import certified_img from "../../images/site-img/rng-iso-usp-v2.webp";
import screen_three from "../../images/site-img/slide3.png";
import CasinoIcon from "@mui/icons-material/Casino";
import screen_four from "../../images/site-img/ludo_home.png";
import screen_five from "../../images/site-img/rummy_home.png";
import verified_img from "../../images/site-img/safe-pay-v2.webp";
import rng_img from "../../images/site-img/rng-v2.webp";
import pure_sequance from "../../images/site-img/pure-sequance.svg";
import sequance from "../../images/site-img/sequance.svg";
import vaild_set from "../../images/site-img/valid-set-1.svg";
import customer_one from "../../images/site-img/review-img.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import support_img from "../../images/site-img/support-24-v2.webp";
import secure_img from "../../images/site-img/secure_checkout_07 (1).png";
import protection_img from "../../images/site-img/protection-v2.webp";
import screen_img from "../../images/site-img/Screen 145-landscape.png";
import { EffectCards } from "swiper/modules";
import AndroidIcon from "@mui/icons-material/Android";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AppleIcon from "@mui/icons-material/Apple";
import StarIcon from "@mui/icons-material/Star";
import Slider from "react-slick";
import Footer from "../../Components/Footer/Footer";
import Header from "Components/Header/Header";
import "swiper/css/effect-cards";
import "swiper/css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch } from "react-redux";
import { getMgpReleases } from "Redux/LandingPage/action";

function LandingPage() {
  const dispatch = useDispatch();
  const [mgpReleaseAndroid, setMgpReleaseAndroid] = useState();
  const [mgpReleaseIos, setMgpReleaseIos] = useState();

  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var settingstwo = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 624,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    dispatch(getMgpReleases()).then((res) => {
      if (res?.data?.status === "success") {
        res?.data?.data?.docs.map((item) => {
          if (item?.releaseBuildType == "Android" && item?.isActive == true)
            setMgpReleaseAndroid(item?.releaseBuild);
          if (item?.releaseBuildType == "Ios" && item?.isActive == true)
            setMgpReleaseIos(item?.releaseBuild);
        });
      }
    });
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div style={{ fontFamily: "Josefin Sans" }}>
      <div className="downlode-btns">
        <div className="">
          <button
            className="btn-theme"
            onClick={() => window.open(mgpReleaseIos, "_blank")}
          >
            <AppleIcon />
            Download iOS App
          </button>
          <button
            className="btn-theme"
            onClick={() => window.open(mgpReleaseAndroid, "_blank")}
          >
            <AndroidIcon />
            Download Android App
          </button>
        </div>
      </div>
      <div className="back-to-top">
        {isVisible && (
          <button onClick={scrollToTop} className="back-to-top-button">
            <ArrowUpwardIcon />
          </button>
        )}
      </div>
      <Header />
      <div className="landing-main">
        <main>
          <div className="banner-main container">
            <div className="animation-card">
              <img src={card_img} alt="" />
            </div>

            <div className="d-flex">
              <div className="banner-txt d-flex">
                <div>
                  <h1>
                    Play cash rummy games <br /> for full deposit value
                  </h1>
                  <div className="d-flex contant-main">
                    <div className="txt-white d-flex game-content">
                      <img src={tournaments_img} alt="" />
                      <p>
                        Free weekly tournaments
                        <br /> of 40 Lakhs
                      </p>
                    </div>
                    <div className="txt-white d-flex game-content">
                      <img src={tournaments_img} alt="" />
                      <p>Free weekly tournaments of 40 Lakhs</p>
                    </div>
                  </div>
                  <div className="banner-downlode-btn">
                    <button
                      className="downlode-theme"
                      onClick={() => window.open(mgpReleaseIos, "_blank")}
                    >
                      <AppleIcon />
                      Download IOS App
                    </button>
                    <button
                      className="downlode-theme"
                      onClick={() => window.open(mgpReleaseAndroid, "_blank")}
                    >
                      <AndroidIcon />
                      Download Android App
                    </button>
                  </div>
                </div>
                <div className="screen-img">
                  <div className="screen-one avatar">
                    <img src={screen_one} alt="" />
                  </div>
                  <div className="screen-two avatar">
                    <img src={screen_two} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="searvice-part container">
            <div className="service-main">
              <div className="service-part">
                <img src={gameplay} alt="" />
                <p>Smooth gameplay experience</p>
              </div>
              <div className="service-part">
                <img src={moves_img} alt="" />
                <p>Check game moves</p>
              </div>
              <div className="service-part">
                <img src={certified_img} alt="" />
                <p>RNG & ISO certified</p>
              </div>
              <div className="service-part">
                <img src={instant_img} alt="" />
                <p>Instant withdrawals</p>
                <div className="payment-option">
                  <ul>
                    <li>
                      <img src={gpay} alt="" />
                    </li>
                    <li>
                      <img src={phone_pay} alt="" />
                    </li>
                    <li>
                      <img src={paytm} alt="" />
                    </li>
                    <li>
                      <img src={imps} alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-main container">
            <div className="slider-heading">
              <h2>Get hooked with Playship experience</h2>
            </div>
            <div className="slider-conteant">
              <Slider {...settings}>
                <div>
                  <div className="slider-box-main d-flex">
                    <div className="slider-box-one">
                      <h3 class="slide-counter md:mb-24 mb-10">1</h3>
                      <h2 class="md:font-48 md:line-height-56 font-20 line-height-24 color md:mb-16 mb-8">
                        Best Gaming Experience
                      </h2>
                      <p class="text-white opacity-50 md:font-20 md:line-height-24 font-12 line-height-16 font-400">
                        Every game gives a simple, yet a rich and rewarding
                        rummy experience to users
                      </p>
                    </div>
                    <div
                      className="slider-box-one"
                      style={{ padding: "35px", paddingBottom: "0px" }}
                    >
                      <div className="slider-screen">
                        <img
                          style={{ border: "none" }}
                          src={screen_three}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="slider-box-main d-flex">
                    <div className="slider-box-one">
                      <h3 class="slide-counter md:mb-24 mb-10">2</h3>
                      <h2 class="md:font-48 md:line-height-56 font-20 line-height-24 color md:mb-16 mb-8">
                        Seamless Ludo gameplay experience
                      </h2>
                      <p class="text-white opacity-50 md:font-20 md:line-height-24 font-12 line-height-16 font-400">
                        Every game gives a simple, yet a rich and rewarding
                        rummy experience to users
                      </p>
                    </div>
                    <div
                      className="slider-box-one"
                      style={{ padding: "35px", paddingBottom: "0px" }}
                    >
                      <div className="slider-screen">
                        <img
                          style={{ border: "none" }}
                          src={screen_four}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="slider-box-main d-flex">
                    <div className="slider-box-one">
                      <h3 class="slide-counter md:mb-24 mb-10">3</h3>
                      <h2 class="md:font-48 md:line-height-56 font-20 line-height-24 color md:mb-16 mb-8">
                        Seamless Rummay gameplay experience
                      </h2>
                      <p class="text-white opacity-50 md:font-20 md:line-height-24 font-12 line-height-16 font-400">
                        Every game gives a simple, yet a rich and rewarding
                        rummy experience to users
                      </p>
                    </div>
                    <div
                      className="slider-box-one"
                      style={{ padding: "35px", paddingBottom: "0px" }}
                    >
                      <div className="slider-screen">
                        <img
                          style={{ border: "none" }}
                          src={screen_five}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="overflow-main" style={{ overflow: "auto" }}>
              <div className="overflow-slide">
                <div className="slider-box-main d-flex">
                  <div className="slider-box-one">
                    <h3 class="slide-counter md:mb-24 mb-10">1</h3>
                    <h2 class="md:font-48 md:line-height-56 font-20 line-height-24 color md:mb-16 mb-8">
                      Seamless gameplay experience
                    </h2>
                    <p class="text-white opacity-50 md:font-20 md:line-height-24 font-12 line-height-16 font-400">
                      Every game gives a simple, yet a rich and rewarding rummy
                      experience to users
                    </p>
                  </div>
                  <div
                    className="slider-box-one"
                    style={{ padding: "20px", paddingBottom: "0px" }}
                  >
                    <div className="slider-screen">
                      <img
                        style={{ border: "none" }}
                        src={screen_three}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="slider-box-main d-flex">
                  <div className="slider-box-one">
                    <h3 class="slide-counter md:mb-24 mb-10">2</h3>
                    <h2 class="md:font-48 md:line-height-56 font-20 line-height-24 color md:mb-16 mb-8">
                      Seamless gameplay experience
                    </h2>
                    <p class="text-white opacity-50 md:font-20 md:line-height-24 font-12 line-height-16 font-400">
                      Every game gives a simple, yet a rich and rewarding rummy
                      experience to users
                    </p>
                  </div>
                  <div
                    className="slider-box-one"
                    style={{ padding: "20px", paddingBottom: "0px" }}
                  >
                    <div className="slider-screen">
                      <img
                        style={{ border: "none" }}
                        src={screen_four}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="slider-box-main d-flex">
                  <div className="slider-box-one">
                    <h3 class="slide-counter md:mb-24 mb-10">3</h3>
                    <h2 class="md:font-48 md:line-height-56 font-20 line-height-24 color md:mb-16 mb-8">
                      Seamless gameplay experience
                    </h2>
                    <p class="text-white opacity-50 md:font-20 md:line-height-24 font-12 line-height-16 font-400">
                      Every game gives a simple, yet a rich and rewarding rummy
                      experience to users
                    </p>
                  </div>
                  <div
                    className="slider-box-one"
                    style={{ padding: "20px", paddingBottom: "0px" }}
                  >
                    <div className="slider-screen">
                      <img
                        style={{ border: "none" }}
                        src={screen_five}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-main container">
            <div className="about-heading">
              <h2>An experience people talk about</h2>
            </div>
            <div className="review-box-main">
              <Slider {...settingstwo}>
                <div>
                  <div className="review-box">
                    <div className="rating-main">
                      <img src={customer_one} alt="" />
                      <div className="rating-star">
                        <ul>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="customer-detalis">
                      <p>Behrouz Khan</p>
                      <span>Lucknow, UP</span>
                    </div>
                    <div className="review-content">
                      <p>
                        I had no idea that a rummy app could be this rewarding.
                        I always look for apps that give more offers and I
                        haven’t found anything better till date. Playship Rummy
                        gives Discount Credits on add cash which I can use to
                        play cash games. The best part is that Discount Credits
                        do not come with a short validity period.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="review-box">
                    <div className="rating-main">
                      <img src={customer_one} alt="" />
                      <div className="rating-star">
                        <ul>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="customer-detalis">
                      <p>Behrouz Khan</p>
                      <span>Lucknow, UP</span>
                    </div>
                    <div className="review-content">
                      <p>
                        I had no idea that a rummy app could be this rewarding.
                        I always look for apps that give more offers and I
                        haven’t found anything better till date. Playship Rummy
                        gives Discount Credits on add cash which I can use to
                        play cash games. The best part is that Discount Credits
                        do not come with a short validity period.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="review-box">
                    <div className="rating-main">
                      <img src={customer_one} alt="" />
                      <div className="rating-star">
                        <ul>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="customer-detalis">
                      <p>Behrouz Khan</p>
                      <span>Lucknow, UP</span>
                    </div>
                    <div className="review-content">
                      <p>
                        I had no idea that a rummy app could be this rewarding.
                        I always look for apps that give more offers and I
                        haven’t found anything better till date. Playship Rummy
                        gives Discount Credits on add cash which I can use to
                        play cash games. The best part is that Discount Credits
                        do not come with a short validity period.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="review-box">
                    <div className="rating-main">
                      <img src={customer_one} alt="" />
                      <div className="rating-star">
                        <ul>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="customer-detalis">
                      <p>Behrouz Khan</p>
                      <span>Lucknow, UP</span>
                    </div>
                    <div className="review-content">
                      <p>
                        I had no idea that a rummy app could be this rewarding.
                        I always look for apps that give more offers and I
                        haven’t found anything better till date. Playship Rummy
                        gives Discount Credits on add cash which I can use to
                        play cash games. The best part is that Discount Credits
                        do not come with a short validity period.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="review-box">
                    <div className="rating-main">
                      <img src={customer_one} alt="" />
                      <div className="rating-star">
                        <ul>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="customer-detalis">
                      <p>Behrouz Khan</p>
                      <span>Lucknow, UP</span>
                    </div>
                    <div className="review-content">
                      <p>
                        I had no idea that a rummy app could be this rewarding.
                        I always look for apps that give more offers and I
                        haven’t found anything better till date. Playship Rummy
                        gives Discount Credits on add cash which I can use to
                        play cash games. The best part is that Discount Credits
                        do not come with a short validity period.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="review-box">
                    <div className="rating-main">
                      <img src={customer_one} alt="" />
                      <div className="rating-star">
                        <ul>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                          <li>
                            <StarIcon />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="customer-detalis">
                      <p>Behrouz Khan</p>
                      <span>Lucknow, UP</span>
                    </div>
                    <div className="review-content">
                      <p>
                        I had no idea that a rummy app could be this rewarding.
                        I always look for apps that give more offers and I
                        haven’t found anything better till date. Playship Rummy
                        gives Discount Credits on add cash which I can use to
                        play cash games. The best part is that Discount Credits
                        do not come with a short validity period.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className="safe-certified container">
            <div className="certified-heading">
              <h2>Safe & certified</h2>
              <img src={secure_img} width="200px" alt="" />
            </div>
            <div className="certified-box-main">
              <div className="certified-box-theme">
                <div>
                  <div class="container">
                    <div class="avatar">
                      <img src={verified_img} alt="" />
                    </div>
                  </div>
                  <p>Safe, secure, quick, and verified payment channels</p>
                </div>
              </div>
              <div className="certified-box-theme">
                <div>
                  <div class="container">
                    <div class="avatar">
                      <img src={rng_img} alt="" />
                    </div>
                  </div>
                  <p>RNG-certified gaming experience</p>
                </div>
              </div>
              <div className="certified-box-theme">
                <div>
                  <div class="container">
                    <div class="avatar">
                      <img src={protection_img} alt="" />
                    </div>
                  </div>
                  <p>Fair gameplay. No bots. 24x7 monitoring.</p>
                </div>
              </div>
              <div className="certified-box-theme">
                <div>
                  <div class="container">
                    <div class="avatar">
                      <img src={support_img} alt="" />
                    </div>
                  </div>
                  <p>24X7 customer support in 5 regional languages</p>
                </div>
              </div>
            </div>
          </div>
          <div className="how-to-play">
            <div className="bg-img">
              <div className="play-heading">
                <h4>How to Play Game</h4>
                <p>
                  A casino is a facility for certain types of gambling. Casinos
                  are often built combined with hotels, resorts.
                </p>
              </div>
              <div className="container">
                <div className="how-item-main">
                  <div class="how-item active">
                    <div class="how-item__thumb">
                      <PersonAddAltIcon />
                      <div class="badge badge--lg badge--round radius-50">
                        01
                      </div>
                    </div>
                    <div class="how-item__content">
                      <h4 class="title">Sign Up First &amp; Login</h4>
                    </div>
                  </div>
                  <div class="how-item active">
                    <div class="how-item__thumb">
                      <AddCardIcon />
                      <div class="badge badge--lg badge--round radius-50">
                        02
                      </div>
                    </div>
                    <div class="how-item__content">
                      <h4 class="title">Complete you Profile</h4>
                    </div>
                  </div>
                  <div class="how-item-none how-item">
                    <div class="how-item__thumb">
                      <CasinoIcon />
                      <div class="badge badge--lg badge--round radius-50">
                        03
                      </div>
                    </div>
                    <div class="how-item__content">
                      <h4 class="title">Choose a Game & Play</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rummy-game container">
            <div className="rummy-heading">
              <h2>Play Rummy Game Online on Playship Rummy</h2>
            </div>
            <div className="rummy-part">
              <div className="rummy-content">
                <p>
                  Indian rummy is one of the oldest, most exciting card games
                  that test a player's many skills–from strategy to probability
                  analysis. Known to have started as early as the 16th century,
                  this 13-card rummy game has become a part of our festivities
                  and has reached every corner of the country, thanks to its now
                  online presence. It is played between 2 to 6 players. The
                  game's objective is to be the first to make a valid
                  declaration. A declaration means to sort all cards into
                  groups, and a valid declaration includes at least two
                  sequences (one of which is mandatorily a pure sequence), and
                  the rest of the cards must be grouped into sequences or sets.
                </p>
                <p>
                  Playship Rummy has a community of more than 1.5 crore rummy
                  players from all over the country. It has huge tournaments
                  that boast prize pools of multiple lakhs, and many of these.
                </p>
              </div>
              <div className="rummy-content">
                <Swiper
                  effect={"cards"}
                  grabCursor={true}
                  modules={[EffectCards]}
                  className="mySwiper"
                >
                  <SwiperSlide></SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          <div className="Play-ludo container">
            <div className="ludo-heading">
              <h2>Play Ludo Game Online on Bigwin Ludo</h2>
            </div>
            <div className="ludo-main">
              <div>
                <div class="board  loaded">
                  <div class="home"></div>
                  <div class="base r ">
                    <div class="inner sparks">
                      <div class="token red animate__animated animate__tada">
                        {" "}
                      </div>
                    </div>
                  </div>
                  <div class="base g">
                    <div class="inner ">
                      <div class="token green animate__animated animate__tada">
                        {" "}
                      </div>
                      <div class="token green animate__animated animate__tada">
                        {" "}
                      </div>
                      <div class="token green animate__animated animate__tada">
                        {" "}
                      </div>
                    </div>
                  </div>
                  <div class="base y">
                    <div class="inner  ">
                      <div class="token yellow animate__animated animate__tada">
                        {" "}
                      </div>
                      <div class="token yellow animate__animated animate__tada">
                        {" "}
                      </div>
                      <div class="token yellow animate__animated animate__tada">
                        {" "}
                      </div>
                    </div>
                  </div>
                  <div class="base b">
                    <div class="inner ">
                      <div class="token blue animate__animated animate__tada">
                        {" "}
                      </div>
                      <div class="token blue animate__animated animate__tada">
                        {" "}
                      </div>
                    </div>
                  </div>

                  <div class="cell race-1 safe-zone start-r " title="1">
                    <div class="inner">
                      <div class="token red animate__animated animate__tada">
                        {" "}
                      </div>{" "}
                    </div>
                  </div>
                  <div class="cell race-2" title="2"></div>
                  <div class="cell race-3" title="3"></div>
                  <div class="cell race-4" title="4">
                    <div class="inner">
                      <div class="token red animate__animated animate__tada">
                        {" "}
                      </div>
                      <div class="token red animate__animated animate__tada">
                        {" "}
                      </div>
                    </div>
                  </div>
                  <div class="cell race-5" title="5"></div>
                  <div class="cell race-6" title="6"></div>
                  <div class="cell race-7" title="7"></div>
                  <div class="cell race-8" title="8"></div>
                  <div class="cell race-9 safe-zone " title="9"></div>
                  <div class="cell race-10" title="10"></div>
                  <div class="cell race-11" title="11"></div>
                  <div class="cell race-12   end-g" title="12"></div>
                  <div class="cell race-13" title="13"></div>
                  <div class="cell race-14 safe-zone start-g " title="14"></div>
                  <div class="cell race-15" title="15"></div>
                  <div class="cell race-16" title="16"></div>
                  <div class="cell race-17" title="17"></div>
                  <div class="cell race-18" title="18"></div>
                  <div class="cell race-19" title="19"></div>
                  <div class="cell race-20" title="20"></div>
                  <div class="cell race-21" title="21"></div>
                  <div class="cell race-22 safe-zone " title="22"></div>
                  <div class="cell race-23" title="23"></div>
                  <div class="cell race-24" title="24"></div>
                  <div class="cell race-25   end-y" title="25"></div>
                  <div class="cell race-26" title="26">
                    <div class="inner">
                      <div class="ficha green animate__animated animate__tada">
                        {" "}
                      </div>
                    </div>
                  </div>
                  <div class="cell race-27 safe-zone start-y " title="27"></div>
                  <div class="cell race-28" title="28"></div>
                  <div class="cell race-29" title="29"></div>
                  <div class="cell race-30" title="30"></div>
                  <div class="cell race-31" title="31"></div>
                  <div class="cell race-32" title="32"></div>
                  <div class="cell race-33" title="33"></div>
                  <div class="cell race-34" title="34">
                    <div class="inner">
                      <div class="token yellow animate__animated animate__tada"></div>
                    </div>
                  </div>
                  <div class="cell race-35 safe-zone " title="35"></div>
                  <div class="cell race-36" title="36"></div>
                  <div class="cell race-37" title="37"></div>
                  <div class="cell race-38   end-b" title="38"></div>
                  <div class="cell race-39" title="39"></div>
                  <div class="cell race-40 safe-zone start-b " title="40"></div>
                  <div class="cell race-41" title="41"></div>
                  <div class="cell race-42" title="42"></div>
                  <div class="cell race-43" title="43"></div>
                  <div class="cell race-44" title="44">
                    {" "}
                  </div>
                  <div class="cell race-45" title="45"></div>
                  <div class="cell race-46" title="46"></div>
                  <div class="cell race-47" title="47"></div>
                  <div class="cell race-48 safe-zone " title="48">
                    <div class="inner">
                      <div class="token blue animate__animated animate__tada">
                        {" "}
                      </div>
                      <div class="token blue animate__animated animate__tada">
                        {" "}
                      </div>
                      <div class="token green animate__animated animate__tada">
                        {" "}
                      </div>
                    </div>
                  </div>
                  <div class="cell race-49" title="49"></div>
                  <div class="cell race-50" title="50"></div>
                  <div class="cell race-51   end-r" title="51"></div>
                  <div class="cell race-52" title="52"></div>

                  <div class="r final">
                    <div class="cell r-f-1 "> </div>
                    <div class="cell r-f-2 "> </div>
                    <div class="cell r-f-3 "> </div>
                    <div class="cell r-f-4 "> </div>
                    <div class="cell r-f-5 "> </div>
                    <div class="cell r-f-6 transparent "> </div>
                  </div>
                  <div class="g final">
                    <div class="cell g-f-1 "> </div>
                    <div class="cell g-f-2 "> </div>
                    <div class="cell g-f-3 "> </div>
                    <div class="cell g-f-4 "> </div>
                    <div class="cell g-f-5 "> </div>
                    <div class="cell g-f-6 transparent "> </div>
                  </div>
                  <div class="y final">
                    <div class="cell y-f-6 transparent "> </div>
                    <div class="cell y-f-5 "> </div>
                    <div class="cell y-f-4 "> </div>
                    <div class="cell y-f-3 "> </div>
                    <div class="cell y-f-2 "> </div>
                    <div class="cell y-f-1 "> </div>
                  </div>
                  <div class="b final">
                    <div class="cell b-f-6 transparent "> </div>
                    <div class="cell b-f-5 "> </div>
                    <div class="cell b-f-4 "> </div>
                    <div class="cell b-f-3 "> </div>
                    <div class="cell b-f-2 "> </div>
                    <div class="cell b-f-1 "> </div>
                  </div>
                </div>
                <div class="dice dice-r animate__animated ">
                  <div class="face-2 ">
                    <span class="pip"></span>
                    <span class="pip"></span>
                  </div>
                  <div class=" face-3 ">
                    <span class="pip"></span>
                    <span class="pip"></span>
                    <span class="pip"></span>
                  </div>
                  <div class="face-4 ">
                    <div class="col">
                      <span class="pip"></span>
                      <span class="pip"></span>
                    </div>
                    <div class="col">
                      <span class="pip"></span>
                      <span class="pip"></span>
                    </div>
                  </div>
                  <div class="face-5 ">
                    <div class="col">
                      <span class="pip"></span>
                      <span class="pip"></span>
                    </div>
                    <div class="col">
                      <span class="pip"></span>
                    </div>
                    <div class="col">
                      <span class="pip"></span>
                      <span class="pip"></span>
                    </div>
                  </div>
                  <div class="face-6 ">
                    <div class="col">
                      <span class="pip"></span>
                      <span class="pip"></span>
                      <span class="pip"></span>
                    </div>
                    <div class="col">
                      <span class="pip"></span>
                      <span class="pip"></span>
                      <span class="pip"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ludo-content">
                <p>
                  Indian rummy is one of the oldest, most exciting card games
                  that test a player's many skills–from strategy to probability
                  analysis. Known to have started as early as the 16th century,
                  this 13-card rummy game has become a part of our festivities
                  and has reached every corner of the country, thanks to its now
                  online presence. It is played between 2 to 6 players. The
                  game's objective is to be the first to make a valid
                  declaration. A declaration means to sort all cards into
                  groups, and a valid declaration includes at least two
                  sequences (one of which is mandatorily a pure sequence), and
                  the rest of the cards must be grouped into sequences or sets.
                </p>
                <p>
                  Playship Rummy has a community of more than 1.5 crore rummy
                  players from all over the country. It has huge tournaments
                  that boast prize pools of multiple lakhs, and many of these
                  are even free to enter! There are virtually unlimited practice
                  games designed to help new players before they enter real cash
                  rummy games and tournaments.
                </p>
                <p>
                  Along with all the fun, Playship Rummy ensures a fair and
                  secure gaming experience. We have a dedicated customer service
                  team to assist its players with any issues, anytime. Support
                  is available 24×7, in 5 languages for added peace of mind.
                </p>
              </div>
            </div>
          </div>
          <div className="games-content container">
            <div className="screen-img">
              <h2>Rummy Game Variants on the Playship Rummy App</h2>
              <p>
                Indian rummy is one of the oldest, most exciting card games that
                test a player's many skills–from strategy to probability
                analysis. Known to have started as early as the 16th century,
                this 13-card rummy game has become a part of our festivities and
                has reached every corner of the country, thanks to its now
                online presence. It is played between 2 to 6 players. The game's
                objective is to be the first to make a valid declaration. A
                declaration means to sort all cards into groups, and a valid
                declaration includes at least two sequences (one of which is
                mandatorily a pure sequence), and the rest of the cards must be
                grouped into sequences or sets.
              </p>
              <img src={screen_img} className="" alt="" />
            </div>
            <div className="paragrapg-txt">
              <h4>Rummy Game Variants on the Playship Rummy App</h4>
              <p>
                Rummy is a popular card game because it has something for
                everyone. Different formats have different durations and
                strategies, which appeal to different kinds of players. Playship
                Rummy offers all the fan-favourite rummy formats in both free
                and real cash variations–Points, Pool, and Deals.
              </p>
              <h5>Points Rummy</h5>
              <ul>
                <li>
                  <p>
                    Points rummy is the fastest variation of rummy online since
                    there’s only one deal in each game. Each point has a
                    monetary value (called point value). On Playship Rummy,
                    point values start from $0.05 and go all the way up to $500.
                  </p>
                </li>
                <li>
                  <p>Your total Buy-In would be:</p>
                </li>
                <li>
                  <p>
                    (Point value of a game) × (Maximum points you can get in a
                    game)
                  </p>
                </li>
                <li>
                  <p>
                    The maximum points a player can get is always 80, regardless
                    of their hand.
                  </p>
                </li>
                <li>
                  <p>
                    So, if 1 point = $0.05, your Buy-In would be $0.05 × 80 =
                    $4.
                  </p>
                </li>
                <li>
                  <p>
                    If you drop in the first round, it would cost you 20 points
                    (or $1, in this example). Dropping midway would cost 40
                    points (or $2).
                  </p>
                </li>
                <li>
                  <p>The winner’s prize is calculated as follows:</p>
                </li>
                <li>
                  <p>
                    (Sum total of other players’ points) × (Point value) ×
                    (Winning Multiplier)
                  </p>
                </li>
                <li>
                  <p>
                    The Winning Multiplier is usually between 0.85 to 0.89 on
                    Playship Rummy, and it depends on the number of players and
                    point value.
                  </p>
                </li>
              </ul>
              <div>
                <h5>Rummy Tournaments</h5>
                <p>
                  There are many rummy tournaments available to players on
                  Playship Rummy. Tournaments have a fixed number of available
                  seats and a player can register for them in advance. There are
                  both free and cash tournaments available, where players can
                  win from big prize pools.
                </p>
                <p>
                  Playship Rummy offers all these different variants of rummy
                  where players can win real cash; however, beginners are
                  encouraged to try the practice games first before entering
                  real money games. In case you have any issues during your
                  tournaments, you can reach out to our 24×7 customer support to
                  assist you immediately.
                </p>
              </div>
              <div>
                <h5>Safe and Secure Rummy Games on Playship Rummy</h5>
                <p>
                  Playship Rummy is committed to providing safe and secure rummy
                  gameplay to all its players. All the rummy games are highly
                  secured to give a fair playing experience on the platform.
                </p>
                <p>
                  Playship Rummy takes the utmost care of players’ accounts and
                  monetary transactions so they can enjoy rummy without any
                  tension. Learn about the various protocols used on the
                  platform:
                </p>
              </div>
              <div>
                <h5>RNG Certification</h5>
                <p>
                  RNG stands for Random Number Generator. This certification is
                  given by iTech Gaming Labs and is used to shuffle and
                  distribute cards on the game table fairly. Nobody knows what
                  cards you may get, not even us.
                </p>
              </div>
              <div>
                <h5>ISO Certification</h5>
                <p>
                  The Playship Rummy app meets the internationally accepted
                  standards for quality.
                </p>
              </div>
              <div>
                <h5>SSL Connection</h5>
                <p>
                  SSL is a standard technology for securing an internet
                  connection between two servers with data encrypting. It
                  prevents hackers from seeing or stealing any of the
                  information a player shares on the app, including personal or
                  financial data.
                </p>
              </div>
              <div>
                <h4>Rummy Game Rules and How to Play</h4>
                <p>
                  Indian Rummy is played between 2 to 6 players and starts with
                  13 cards being distributed to each player. Generally, 2 decks
                  of cards are used in the game, including printed Jokers. Each
                  game also has wild card Jokers–a card is randomly drawn from
                  the deck, and all cards of the same rank, irrespective of the
                  suit, are designated as the wild card jokers for the game. The
                  objective of the game is to sort/group all 13 cards into
                  sequences (consecutive cards of the same suit) or/and sets
                  (same ranked card from different suits).
                </p>
                <p>
                  In each turn, a player can draw from either the open or the
                  closed deck, and needs to dispose of one card that is useless
                  to them. Once all 13 cards are grouped, the player needs to
                  make a declaration.
                </p>
                <p>
                  At least one pure sequence - consecutive cards of the same
                  suit, without the use of any Joker card. If you have a wild
                  card Joker that fits the sequence, then it is okay to use.
                </p>
                <p>
                  A second sequence - can be either pure or impure (sequence
                  made using at least 1 Joker card)
                </p>
                <p>
                  The rest of the cards can be sequences or sets, there is no
                  rule on that.
                </p>
                <p>
                  The first player to make a valid declaration is considered the
                  winner.
                </p>
                <p>
                  Points in a rummy game are calculated for the ungrouped cards.
                  Different cards have different points:
                </p>
                <p>
                  Numbered cards: All numbered cards have points equal to their
                  face value.
                </p>
                <p>
                  Face cards: King, Queen, and Jack are all equal to 10 points
                  each.
                </p>
                <p>Ace cards: Aces are also equal to 10 points each.</p>
                <p>
                  Joker cards: Both printed and wild card Jokers are equal to 0
                  points.
                </p>
                <p>
                  The winner would be said to have 0 points as they have grouped
                  all their cards.
                </p>
                <p>
                  Let’s say at the end of the game, you have the following
                  cards:
                </p>
                <p>A♦ K♦ Q♦ J♦ | 2♠ 3♠ 🃏 | 5♥ 5♦ 5♠ | 3♣ 7♣ 11♥</p>
                <div className="demo-card">
                  <img src={pure_sequance} alt="" />
                  <img src={sequance} alt="" />
                  <img src={vaild_set} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="questions-main container">
            <div className="questions-heading">
              <h4>Frequently asked questions</h4>
              {/* <button>View All</button> */}
            </div>
            <div className="questions-space">
              <div className="questions">
                <h5>How do I create a new account on Playship?</h5>
                <p>
                  You would only need a mobile number to get started. Get your
                  number OTP Verified, and you are good to go.
                </p>
              </div>
              <div className="questions">
                <h5>Is it legal to play online rummy in India?</h5>
                <p>
                  Yes, it's legal to play online rummy in India. The game had
                  been declared a skill by the Hon'ble Supreme Court of India in
                  1968, which makes playing rummy 100% legal.
                </p>
              </div>
              <div className="questions">
                <h5>How to play rummy in Playship?</h5>
                <p>
                  It's simple! Download the app from the website, Playstore,
                  Apple App Store, register, and you are ready to play rummy
                  online.
                </p>
              </div>
              <div className="questions">
                <h5>I don’t know the rules of rummy. Can I still play?</h5>
                <p>
                  Yes! Playing rummy online on Playship is free and easy to
                  learn. All you need to do is download the app!
                </p>
              </div>
              <div className="questions">
                <h5>What is RNG Certification?</h5>
                <p>
                  RNG Stands for Random Number generator. This certification is
                  given by iTech Gaming Labs and it ensures that all the cards
                  are dealt randomly to users. Hence the games are fair.
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
