import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import site_logo from "../../../src/images/site-img/sitelogo.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { getDashBoardUsers } from "Redux/LandingPage/action";

function Header() {
  const dispatch = useDispatch();
  const [open, Setopen] = useState(false);
  const [totalUser, SetTotalUser] = useState(0);
  const [isLoding, setIsLoding] = useState(true);

  const handleshow = () => {
    Setopen(!open);
  };
  const stickyRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const sticky = stickyRef.current;
      if (sticky) {
        const pos = sticky.offsetTop + 150;
        if (window.scrollY > pos) {
          sticky.classList.add("stickyhead");
        } else if (window.scrollY < pos - 50) {
          sticky.classList.remove("stickyhead");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isLoding) {
      dispatch(getDashBoardUsers()).then((res) => {
        if (res?.data?.status === "success") {
          SetTotalUser(res?.data?.data?.totalUser)
          setIsLoding(false);
        }
      });
    }
  }, []);


  return (
    <>
      <div className="landing-main">
        <nav className=" " ref={stickyRef} id="sticky-wrap">
          <div className="container header-main">
            <div className="site-logo d-flex">
              <Link to="/">
                <img src={site_logo} width="80px" alt="" />
              </Link>
              <div className="online-player">
                <div class="pulsating-circle"></div>
                {isLoding ? <p> Loding... </p> : <p> {totalUser} Online</p>}
              </div>
            </div>
            <div>
              <ul className="nav-manu">
                <Link to="/privacypolicy">
                  <li>Privacy Policy</li>
                </Link>
                <Link to="/term-condition">
                  <li>Term and Condition</li>
                </Link>
              </ul>
            </div>
            <div className="toggal-btn">
              <div className="togal-icon" onClick={handleshow}>
                <MenuIcon />
              </div>
              {open ? (
                <div className="moblie-menu">
                  <ul className="m-main-menu">
                    <Link to="/privacypolicy">
                      <li>privacy policy</li>
                    </Link>
                    <Link to="/term-condition">
                      <li>term and condition</li>
                    </Link>
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Header;
